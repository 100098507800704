import * as React from "react"
import {useMemo, useState} from "react"
import {InfiniteCarousel} from "../components/home/InfiniteCarousel";
import * as styles from "../styles/pages/home.module.scss"
import AnimatedLogo from "../components/AnimatedLogo";
import {HomePageModel} from "../store/models/home-page";
import {ResidentLink} from "../store/models/resident";
import SEO from "../components/SEO";
import {HomeImageCarousel} from "../components/home/HomeImageCarousel";
import AnimatedBorder from "../components/AnimatedBorder";
import {MenuDialog} from "../components/menu/MenuDialog";
import StyledText, {TextStyles} from "../components/typography";
import PageScroller from "../components/PageScroller";
import MenuBox from "../components/menu/MenuBox";
import HomeHeader from "../components/home/HomeHeader";
import {MenuModel} from "../store/models/menu";
import Button from "../components/common/button/Button";
import useMediaQuery from "../helpers/use-media-query";
import ProgressiveImage from "../components/common/ProgressiveImage";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type HomePageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse
    }
}

const HomePage = (props: HomePageProps) => {
  const showMobileImages = useMediaQuery('(max-width: 599px)');
  const isMobileView = useMediaQuery('(max-width: 1023px)');
  const imagesArr: 'mobileImages' | 'images' = useMemo(() => showMobileImages ? 'mobileImages' : 'images', [showMobileImages])
  const [open, setOpen] = useState(false);
  const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);
  const homePageModel: HomePageModel = HomePageModel.fromJSON(props.serverData.page);

  return (
      <>
        <SEO seo={homePageModel.seo}></SEO>
        <PageScroller isMobileView={isMobileView}>
          <div className={styles.page}>
            <HomeHeader
                setMenuOpen={setOpen}
                ctaLink={menuModel.cta?.url?.link || '#'}
                ctaLabel={menuModel.cta?.label || ''}
                menuLabel={menuModel.menuBtn || ''}
            />
            <MenuDialog open={open} setOpen={setOpen} menu={menuModel}/>
            {homePageModel[imagesArr] && <HomeImageCarousel images={homePageModel[imagesArr]} />}
            <AnimatedBorder />
            <div className={styles.hero}>
                <div className={styles.heroSection}>
                    <AnimatedLogo />
                    <h1 className={styles.title}>
                        {homePageModel.title}
                    </h1>
                    <Button variant='contained'  href={homePageModel.cta ? homePageModel.cta.url?.link : ''} trackInitiateEvent>
                        <StyledText variant={TextStyles.button} tag="span">
                            {homePageModel.cta?.label}
                        </StyledText>
                    </Button>
                </div>

                <div>
                    <p className={styles.subTitleTotal}>
                        {homePageModel.subTitleTotal}
                    </p>

                    <p className={styles.subTitle}>
                        {homePageModel.subTitle}
                    </p>
                </div>
            </div>
            <div className={styles.carousel}>
              <InfiniteCarousel animationSpeed={homePageModel.residents ? homePageModel.residents?.length * 17 : 40}>
                {homePageModel.residents && homePageModel.residents.map((resident: ResidentLink, index) =>
                    resident.resident ?
                        <div className={styles.carouselLogoContainer} key={index}>
                            {resident.resident.whiteLogo && (
                                <ProgressiveImage
                                    image={resident.resident.whiteLogo}
                                />
                            )}
                        </div>
                        : <></>
                )}
              </InfiniteCarousel>
            </div>
          </div>
          <div>
            <AnimatedBorder static />
            <MenuBox menu={menuModel}/>
          </div>
        </PageScroller>
      </>
  )
}

export default HomePage

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.homePage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
